import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import { ProductType } from 'types';

import 'styles/components/product.css';
import { formatMoney } from 'utils/format';

function ProductFooter({ quantity, onQuantityChange }) {
  const [counter, setCounter] = useState(quantity);

  const handleChange = (count) => {
    onQuantityChange(count);
  };

  const increase = () => {
    const count = counter < 10 ? counter + 1 : 10;
    setCounter(count);
    handleChange(count);
  };

  const decrease = () => {
    const count = counter - 1;
    setCounter(count);
    handleChange(count);
  };

  if (counter === 0) {
    return (
      <button type="button" className="product__btn" onClick={increase}>Добавить</button>
    );
  }

  return (
    <div className="product__cart">
      <button type="button" className="product__btn product__btn--quantity" onClick={decrease}>-</button>
      <input type="text" className="product__quantity" readOnly value={counter} onChange={handleChange} />
      <button type="button" className="product__btn product__btn--quantity" onClick={increase}>+</button>
    </div>
  );
}

export default function Product({ data, quantity, onQuantityChange }) {
  const containerRef = useRef(null);

  const handleQuantityChange = (count) => {
    onQuantityChange(data.id, count);
  };

  useEffect(() => {
    const container = containerRef.current;

    const delegate = '[data-fancybox]';
    const options = {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return (
    <div className="product">
      <div className="product_preview" ref={containerRef}>
        <a data-fancybox="gallery" href={data.image}>
          <img src={data.imagePreview} className="product__image" loading="lazy" alt="product" />
        </a>
      </div>
      <div className="product__body">
        <h2 className="product__title">{data.title}</h2>

        {data.price && (
          <span className="product__price">
            Стоимость:
            <strong>{formatMoney(data.price)}</strong>
          </span>
        )}

        <p className="product__desc">{data.description}</p>
      </div>
      {onQuantityChange && (
        <div className="product__footer">
          <ProductFooter
            quantity={quantity}
            onQuantityChange={handleQuantityChange}
          />
        </div>
      )}
    </div>
  );
}

ProductFooter.propTypes = {
  quantity: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};

Product.propTypes = {
  data: ProductType.isRequired,
  quantity: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};
