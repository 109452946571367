import React from 'react';
// import { useLocation } from 'react-router';

// import { MenuApi } from 'api';
import {
  Message,
  Layout,
  // Loading,
  // EmptyList,
  TgMain,
  // ProductList,
} from 'components';

export default function FailPage() {
  // const { state } = useLocation();

  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);

  // const loadData = async () => {
  //   setIsLoading(true);

  //   try {
  //     const menuData = await MenuApi.getMenu(state.menuId);
  //     setMenu(menuData);
  //   } catch (e) {
  //     setError(e);
  //   }

  //   setIsLoading(false);
  // };

  const onContinue = () => {
    window.Telegram.WebApp.close();
  };

  // useEffect(() => {
  //   loadData();
  // }, []);

  // if (isLoading) {
  //   return <Loading text="Загрузка..." />;
  // }

  // if (error) {
  //   return <EmptyList text="Не удалось загрузить страницу." />;
  // }

  return (
    <Layout>
      <Message text="Не удалось создать заказ." />
      {/* <Message text={state.timetable.menu.recommendationDescription || ''} /> */}
      {/* <ProductList products={state.timetable.menu.recommendations || []} /> */}
      <TgMain text="Закрыть" onClick={onContinue} />
    </Layout>
  );
}
