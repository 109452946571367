import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/choices.css';

export default function Choices({ items, value, onChange }) {
  return (
    <div className="choices">
      {items.map((item) => (
        <label htmlFor={`radio-${item.value}`} className="choices__item" key={item.value}>
          <input
            className="choices__control"
            id={`radio-${item.value}`}
            type="radio"
            name="radio"
            value={item.value}
            defaultChecked={value === item.value}
            onChange={onChange}
          />
          <div className="choices__button">
            {item.label}
          </div>
        </label>
      ))}
    </div>
  );
}

Choices.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
