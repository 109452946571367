import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/empty-list.css';

export default function EmptyList({ text }) {
  return (
    <div className="empty-list">
      <span className="empty-list__text">{text}</span>
    </div>
  );
}

EmptyList.propTypes = {
  text: PropTypes.string.isRequired,
};
