import { api } from 'config/axios';

const OrderApi = {
  create: async (order) => {
    const response = await api.request({
      url: '/orders/',
      method: 'POST',
      data: order,
    });

    return response.data;
  },
  get: async (id) => {
    const response = await api.request({
      url: `/orders/${id}/`,
      method: 'GET',
    });

    return response.data;
  },
  place: async (id) => {
    const response = await api.request({
      url: `/orders/${id}/place/`,
      method: 'POST',
    });

    return response.data;
  },
};

export default OrderApi;
