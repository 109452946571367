import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/loading.css';

export default function Loading({ text }) {
  return (
    <div className="loading">
      <span className="loading__text">{text}</span>
    </div>
  );
}

Loading.propTypes = {
  text: PropTypes.string.isRequired,
};
