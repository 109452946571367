import { api } from 'config/axios';

const UserApi = {
  getCurrent: async () => {
    const response = await api.request({
      url: '/user/',
      method: 'GET',
    });

    return response.data;
  },
};

export default UserApi;
