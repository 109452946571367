import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/invoice.css';
import moment from 'moment';
import { formatMoney } from 'utils/format';

export default function Invoice({ order }) {
  const date = moment(order.deliveryDate).format('DD MMMM YYYY г.');
  const startedAt = order.deliveryStartedAt ? order.deliveryStartedAt.slice(0, -3) : null;
  const endedAt = order.deliveryEndedAt ? order.deliveryEndedAt.slice(0, -3) : null;

  return (
    <div className="invoice">
      <h3 className="invoice__title">{`Ваш заказ № ${order.id}`}</h3>
      <ul className="invoice__delivery">
        <li>
          Имя:
          <strong>{order.firstName}</strong>
        </li>
        <li>
          Фамилия:
          <strong>{order.lastName}</strong>
        </li>
        <li>
          E-mail:
          <strong>{order.email}</strong>
        </li>
        <li>
          Номер телефона:
          <strong>{order.phone}</strong>
        </li>
        <li>
          Тип:
          {order.deliveryDate ? (
            <strong>Доставка</strong>
          ) : (
            <strong>Самовывоз</strong>
          )}
        </li>
        <li>
          Район:
          <strong>{order.deliveryDistrict}</strong>
        </li>
        <li>
          Адрес:
          <strong>{order.deliveryAddress}</strong>
        </li>
        {order.deliveryDate !== null && (
          <li>
            Дата:
            <strong>{date}</strong>
          </li>
        )}
        {order.deliveryDate !== null && (
          <li>
            Время:
            <strong>{`с ${startedAt} до ${endedAt}`}</strong>
          </li>
        )}

      </ul>

      <span className="invoice__price">
        К оплате:
        <strong>{formatMoney(order.totalAmount)}</strong>
      </span>

      <table className="invoice__items">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Кол-во</th>
            <th>Цена</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>{formatMoney(item.price)}</td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  );
}

Invoice.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};
