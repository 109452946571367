import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export default function PaymentPage() {
  const form = useRef(null);
  const { state } = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.pay(form.current);
    };

    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <form className="payform-tinkoff" ref={form} style={{ visibility: 'hidden' }}>
      <input type="hidden" name="terminalkey" value="1718869508945DEMO" />
      <input type="hidden" name="frame" value="false" />
      <input type="hidden" name="language" value="ru" />
      <input type="text" placeholder="Сумма заказа" name="amount" value={state.order.totalAmount} required />
      <input type="hidden" placeholder="Номер заказа" name="order" value={state.order.id} />
      <input type="text" placeholder="Описание заказа" name="description" value={`Заказ №${state.order.id}`} />
      <input type="text" placeholder="ФИО плательщика" name="name" value={`${state.order.firstName} ${state.order.lastName}`} />
      <input type="email" placeholder="E-mail" name="email" value={state.order.email} />
      <input type="tel" placeholder="Контактный телефон" name="phone" value={`${state.order.phone}`} />
      <button type="submit">Send</button>
    </form>
  );
}
