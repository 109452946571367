import React from 'react';
import PropTypes from 'prop-types';
// import { motion } from 'framer-motion';

import 'styles/components/layout.css';

export default function Layout({ children, floating }) {
  const className = floating ? 'page page--floating' : 'page';

  // return (
  //   <motion.div
  //     className={className}
  //     initial={{ opacity: 0 }}
  //     animate={{ opacity: 1 }}
  //     exit={{ opacity: 0 }}
  //   >
  //     {children}
  //   </motion.div>
  // );

  return (
    <div className={className}>
      {children}
    </div>
  );
}

Layout.defaultProps = {
  floating: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  floating: PropTypes.node,
};
