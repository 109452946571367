import { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function TgBack({ onClick }) {
  const tgApp = window.Telegram.WebApp;
  const tgView = window.Telegram.WebView;

  const event = 'web_app_setup_back_button';

  useEffect(() => {
    tgView.postEvent(event, false, { is_visible: true });
    tgApp.BackButton.onClick(onClick);

    return () => {
      tgView.postEvent(event, false, { is_visible: false });
      tgApp.BackButton.offClick(onClick);
    };
  });

  return null;
}

TgBack.propTypes = {
  onClick: PropTypes.func.isRequired,
};
