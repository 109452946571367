import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';

import { Routes } from 'routes';
import './app.css';
import 'moment/locale/ru';

import 'styles/shared/form.css';
import 'styles/shared/btn.css';

moment.locale('ru');

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
