import React, { useEffect } from 'react';

import { Message, Layout, TgMain } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OrderApi } from 'api';

export default function ThanksPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onContinue = () => {
    window.Telegram.WebApp.close();
  };

  const createOrder = async (id) => {
    await OrderApi.place(id);
  };

  useEffect(() => {
    const status = searchParams.get('Success');
    const orderId = searchParams.get('OrderId');

    if (status === 'true' && +orderId > 0) {
      createOrder(orderId);
    } else {
      navigate('/fail');
    }
  });

  return (
    <Layout>
      <Message text="Спасибо за Ваш заказ!" />
      <TgMain text="Закрыть" onClick={onContinue} />
    </Layout>
  );
}
