import { api } from 'config/axios';

const CategoryApi = {
  listCategories: async () => {
    const response = await api.request({
      url: '/categories/',
      method: 'GET',
    });

    return response.data;
  },
};

export default CategoryApi;
