import React from 'react';
import PropTypes from 'prop-types';

import { ProductType } from 'types';
import Product from './product';

import 'styles/components/product-list.css';

export default function ProductList({ categories, products, onQuantityChange }) {
  const items = categories.map((category) => ({
    category,
    products: products.filter((product) => product.category === category.id),
  }));

  if (items.length === 0) {
    return (
      <div className="product-list">
        <ProductCategory
          products={products}
          onQuantityChange={onQuantityChange}
        />
      </div>
    );
  }

  return (
    <div className="product-list">
      {items.map((item) => (
        <ProductCategory
          key={item.id}
          category={item.category}
          products={item.products}
          onQuantityChange={onQuantityChange}
        />
      ))}
    </div>
  );
}

function ProductCategory({ category, products, onQuantityChange }) {
  if (products.length === 0) {
    return null;
  }

  return (
    <div className="product-list__category">
      {category && (
        <h3 className="product-list__title">{category.title}</h3>
      )}

      <div className="product-list__items">
        {products.map((product) => (
          <div className="product-list__product" key={product.id}>
            <Product
              data={product}
              quantity={0}
              onQuantityChange={onQuantityChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

ProductList.defaultProps = {
  categories: [],
  onQuantityChange: null,
};

ProductList.propTypes = {
  categories: PropTypes.instanceOf(Array),
  products: PropTypes.arrayOf(ProductType).isRequired,
  onQuantityChange: PropTypes.func,
};

ProductCategory.defaultProps = {
  onQuantityChange: null,
  category: null,
};

ProductCategory.propTypes = {
  category: PropTypes.instanceOf(Object),
  products: PropTypes.arrayOf(ProductType).isRequired,
  onQuantityChange: PropTypes.func,
};
