import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { TimetableApi, CategoryApi } from 'api';
import {
  Loading,
  EmptyList,
  Message,
  ProductList,
  Layout,
  TgMain,
} from 'components';
import moment from 'moment';

export default function ProductsPage() {
  const navigate = useNavigate();

  const [productFields, setProductFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timetable, setTimetable] = useState(null);
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const categoriesData = await CategoryApi.listCategories();
      const timetableData = await TimetableApi.getTodayTimetable();

      setTimetable(timetableData);
      setCategories(categoriesData);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onProductQuantityChange = (id, count) => {
    setProductFields({ ...productFields, [id]: count });
  };

  const onContinue = () => {
    navigate('/order', {
      state: {
        productFields,
        timetable,
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (!timetable || !timetable.menu || !timetable.menu.products) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (!timetable.isOrderCanBeCreated) {
    return (
      <Layout>
        <Message text="Время заказа вышло." />
      </Layout>
    );
  }

  return (
    <Layout>
      <TgMain text="Оформить заказ" onClick={onContinue} escaped={false} />
      <Message text={`Меню на ${moment(timetable.sheduledAt).format('DD MMMM')}`} />
      <ProductList
        categories={categories || []}
        products={timetable.menu.products || []}
        onQuantityChange={onProductQuantityChange}
      />
    </Layout>
  );
}
