import PropTypes from 'prop-types';

const DeliveryPeriodType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string.isRequired,
});

export default DeliveryPeriodType;
